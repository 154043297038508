exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newproject-js": () => import("./../../../src/pages/newproject.js" /* webpackChunkName: "component---src-pages-newproject-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-discussionguides-pages-js": () => import("./../../../src/templates/discussionguides/pages.js" /* webpackChunkName: "component---src-templates-discussionguides-pages-js" */),
  "component---src-templates-insights-pages-js": () => import("./../../../src/templates/insights/pages.js" /* webpackChunkName: "component---src-templates-insights-pages-js" */),
  "component---src-templates-methods-index-js": () => import("./../../../src/templates/methods/index.js" /* webpackChunkName: "component---src-templates-methods-index-js" */),
  "component---src-templates-methods-pages-js": () => import("./../../../src/templates/methods/pages.js" /* webpackChunkName: "component---src-templates-methods-pages-js" */),
  "component---src-templates-projects-index-js": () => import("./../../../src/templates/projects/index.js" /* webpackChunkName: "component---src-templates-projects-index-js" */),
  "component---src-templates-projects-pages-js": () => import("./../../../src/templates/projects/pages.js" /* webpackChunkName: "component---src-templates-projects-pages-js" */)
}

